<style type="text/css">
  header {
    height: 60px;
    background: #232323;
    box-shadow: 0px 0px 10px 5px rgba(100, 180, 180, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;
    color: #dedede;
  }

  p {
    font-size: 24px;
    font-weight: bold;
  }

  .nav__links {
    list-style: none;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: none;
  }

  .nav__links li {
    display: inline-block;
    padding: 0px 20px;
  }
  .nav__links li a {
    color: #dedede;
  }  
  .nav__links li a:hover {
    color: #0088a9;
  }  
  .nav__links li a:visited {
    color: #dedede;
  }
</style>

<header>
  <p>Steve Smodish</p>
  <nav>
    <ul class="nav__links">
      <li>
        <a href="#about">About</a>
      </li>
      <li>
        <a href="#projects">Projects</a>
      </li>
      <li>
        <a href="#contact">Contact</a>
      </li>
    </ul>
  </nav>
</header>
