<style>
  .about {
    padding-top: 20px;
    height: 30vh;
    color: #dedede;
  }
  .about a:link {
    font-size: 20px;
    text-decoration: none;
    color: #dedede;
  }
</style>

<div class="about">
  <a href="#about" name="about">About Me</a>
  <p>After 25 years in the entertainment business, I'm now following my second love - CODE!</p>
</div>
